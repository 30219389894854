// This is the homepage.

import React from "react"
//import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Map from "../components/map"


const Heading1 = styled.h1`
    font-size: 1.8em;
    text-align: center;
    min-height: 85px;
    font-weight:600;


`

const Wrapper = styled.div`
    width: 100%;
    background-position: center;
    padding: 10px;
    padding: 40px 40px 40px 40px;

`

const ContentWrapper = styled.div`
    text-align: center;
    padding: 20px 10px 20px 10px;
    margin: auto;
`

const Content = styled.div`
    width: auto;
    font-size: 1.3em;
    line-height: 20px;
    text-align: center;
    padding: 0px 20px 20px 0px;

`


class BlogIndex extends React.Component {
  render() {

    return (

      <Layout location={this.props.location}>
        <SEO title="Wholesale" />
         <Wrapper><Heading1>Contact</Heading1>
         <ContentWrapper>
            <Content>
  
 
   </Content>
            <Content> Joshua Bailey Bailey Coffee</Content>





            <Content>Electric mail <a href="mailto:TRADING@JBCOFFEE.COM.AU">trading@jbcoffee.com.au</a></Content>
            <Content>20 Provost Street, North Melbourne, Victoria 3051, Australia  </Content>
            <Content>Hard Wired +61 3 93295007</Content>
            <Content>Wireless +61 448 286 642</Content>
            <Content>Postage PO Box 902, North Melbourne, Victoria 3051, Australia</Content>
            <Map />
            </ContentWrapper>
          </Wrapper>
      </Layout>
    )
  }
}

export default BlogIndex